<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-12"
      :label="$t('modulos.unidade_medida.filtro.nome')"
      :placeholder="$t('modulos.unidade_medida.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.Simbolo"
      class="col-12 col-md-6"
      :label="$t('modulos.unidade_medida.filtro.simbolo')"
      :placeholder="$t('modulos.unidade_medida.filtro.simbolo')"
      em-filtro
    />
    <input-text
      v-model="filtro.grandezaFisica"
      class="col-12 col-md-6"
      :label="$t('modulos.unidade_medida.filtro.grandezaFisica')"
      :placeholder="$t('modulos.unidade_medida.filtro.grandezaFisica')"
      em-filtro
    />
    <input-select
      v-model="filtro.unidadeMedidaPadrao"
      class="col-12 col-md-6"
      :label="$t('modulos.unidade_medida.filtro.unidadeMedidaPadrao')"
      :placeholder="$t('modulos.unidade_medida.filtro.unidadeMedidaPadrao')"
      :options="opcoes.booleano"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.sistema"
      class="col-12 col-md-6"
      :label="$t('modulos.unidade_medida.filtro.sistema')"
      :placeholder="$t('modulos.unidade_medida.filtro.sistema')"
      em-filtro
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';

export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        tipoCampo: helpers.TipoCampoAdicionaisEnum,
        booleano: helpers.BoleanoEnum,
      },
    }

  },
};
</script>
