<template>
  <modal-padrao
    ref="modal-fator-conversao"
    max-width="80%"
    :titulo="$t('modulos.unidade_medida.modal.titulo')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="salvar"
  >
    <v-form ref="form" class="row d-flex align-center flex-row justify-space-between w-auto mt-2 mx-0">
      <input-grandeza-fisica
        v-model="grandezaFisica"
        class="col-12 col-md-6"
        :label="$t('modulos.unidade_medida.formulario.grandezaFisica')"
        :multiplos="false"
        obrigatorio
        :show-confirm-before-modal="true"
        :before-abrir-modal="teste"
        @input="unidadePadraoGrandezaFisica"
      />
      <input-unidade-medida
        v-model="unidadeMedidaDeId"
        :idGrandezaFisica="grandezaFisica?.value"
        :buscaUnidadeMedidaGrandezaFisica="true"
        :disabled="!grandezaFisica?.value"
        class="col-12 col-md-6"
        :label="$t('modulos.unidade_medida.tabela.unidadeMedidaPadrao')"
        :multiplos="false"
        :obrigatorio="!!grandezaFisica?.value"
        :confirmacao="{titulo: $t('modulos.unidade_medida.modal_confirmacao.titulo'),
          descricao: $t('modulos.unidade_medida.modal_confirmacao.descricao')
        }"
        @aplicar-selecionados="alteraUnidadePadrao"
      />
    </v-form>
    <div class="col-12">
      <tabela-padrao-prime-vue
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="-1"
        sem-paginacao
        :mostrar-acoes="false"
        :mostrar-seletor="false"
        :mostra-header="false"
      >
        <template v-slot:fatorConversao="{ slotProps }">
          <input-text 
            v-model="slotProps.data.fatorConversao"
            sem-label
            flat
            solo
            class="flex-fill input-tabela-analise-tecnica my-1"
            type="number"
            :disabled="slotProps.data.unidadeMedidaDe.simboloNome == slotProps.data.unidadeMedidaPara.simboloNome
              || grandezaFisica.text.toLowerCase() == 'temperatura'"
          />
        </template>
      </tabela-padrao-prime-vue>
    </div>
  </modal-padrao>
</template>
<script>
import ConversorUnidadeService from '@common/services/cadastros/ConversorUnidadeService.js';
import { ConversorUnidadeModel } from '@common/models/cadastros/ConversorUnidadeModel.js';
import GrandezaFisicaService from '@common/services/cadastros/GrandezaFisicaService.js';
import UnidadeMedidaService from '@common/services/cadastros/UnidadeMedidaService.js';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { InputUnidadeMedida } from '@components/inputs';
import { InputGrandezaFisica } from '@components/inputs';

export default {
  name: 'FatorConversao',
  components: {
    InputUnidadeMedida,
    InputGrandezaFisica
  },
  data() {
    return {
      opcoes: {
        unidadesMedidas: [],
      },
      grandezaFisica: null,
      unidadeMedidaDeId: null,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'unidadeMedidaDe.simboloNome',
            text: this.$t('modulos.unidade_medida.modal.prefixo'),
            sortable: false,
          },
          {
            value: 'unidadeMedidaPara.simboloNome',
            text: this.$t('modulos.unidade_medida.modal.nome'),
            sortable: false,
          },
          {
            value: 'fatorConversao',
            text: this.$t('modulos.unidade_medida.modal.fatorConversao'),
            sortable: false,
          },
          {
            value: 'fatorAdicao',
            text: this.$t('modulos.unidade_medida.modal.fatorAdicao'),
            sortable: false,
          },
        ],
      },
    };
  },
  watch: {
    unidadeMedidaDeId: async function () {
      if (!this.unidadeMedidaDeId) {
        this.tabela.dados = [];
        return;
      }
      await this.listarFatorConversao();
    },
    'grandezaFisica': async function () {
      this.unidadeMedidaDeId = null;
    }
  },
  async mounted() {
    await this.listarUnidadeMedidas();
  },

  methods: {
    teste() {
      if (!this.tabela?.dados?.length) return

      this.confirmar(
        this.$t('modulos.unidade_medida.modal_confirmacao_grandeza_fisica.titulo'),
        this.$t('modulos.unidade_medida.modal_confirmacao_grandeza_fisica.descricao'),
        true,
        false
      ).then(() => {
        this.salvar(false);
      });
    },
    abrirModal: function () {
      this.grandezaFisica = null;
      this.$refs.form?.resetValidation();
      
      this.$refs['modal-fator-conversao'].abrirModal();
    },
    listarFatorConversao: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      const request = new ConversorUnidadeModel({
        UnidadeMedidaDeId: this.unidadeMedidaDeId.value,
        valor: 1,
      });
      await ConversorUnidadeService.listar(request)
        .then((res) => {
          this.tabela.dados = res.data.unidadesMedidasConvertidas.map(i => {
            const dadosTabela = {
              ...i,
              unidadeMedidaPara: {
                ...i.unidadeMedidaPara,
                simboloNome: `${i.unidadeMedidaPara.nome} (${i.unidadeMedidaPara.simbolo})`
              },
              unidadeMedidaDe: {
                simboloNome: `${res.data.unidadeMedidaDe.nome} (${res.data.unidadeMedidaDe.simbolo})`
              }
            }
            return dadosTabela;
          }).sort((a, b) => {
            if (a.unidadeMedidaPara.simboloNome < b.unidadeMedidaPara.simboloNome) return -1;
            if (a.unidadeMedidaPara.simboloNome > b.unidadeMedidaPara.simboloNome) return 1;
            return 0;
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarUnidadeMedidas: async function () {
      let parametros = {
        amountPerPage: 500,
      };
      await UnidadeMedidaService.listar(parametros).then((res) => {
        this.opcoes.unidadesMedidas = new DropdownModel(res.data.items);
      });
    },
    salvar: function (fecharModal = true) {
      if (!this.$refs.form.validate()) return;
      const dadosEnviar = {
        unidadeMedidaDeId: this.unidadeMedidaDeId.value,
        grandezaFisicaId: this.grandezaFisica.value,
        unidadeMedidaConversoes: this.tabela.dados.map( p => {
          return {
            unidadeMedidaParaId: p.unidadeMedidaPara.id,
            fatorConversao: p.fatorConversao,
            fatorAdicao: p.fatorAdicao
          }
        })
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      ConversorUnidadeService.salvar(dadosEnviar)
        .then(() => {
          if (fecharModal) {
            this.$refs['modal-fator-conversao'].fecharModal();
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    alteraUnidadePadrao: function(valor) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      UnidadeMedidaService.alterarUnidadeMedidaPadrao(valor[0].id)
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    unidadePadraoGrandezaFisica: function (grandezaFisica) {
      if (!grandezaFisica?.value) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      GrandezaFisicaService.buscar(grandezaFisica?.value)
        .then((res) => {
          if (res.data.unidadeMedidaPadrao?.nome != null) {
            this.unidadeMedidaDeId = new DropdownModel(res.data.unidadeMedidaPadrao);
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        })
    }
  },
};
</script>
