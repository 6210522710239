import _ from 'lodash';

export class ConversorUnidadeModel {
  constructor({
    valor,
    UnidadeMedidaDeId,
    tipoArrendodamentoUnidadeMedida = 'NaoArredondar',
  }) {
    this.valor = valor;
    this.UnidadeMedidaDeId = UnidadeMedidaDeId;
    this.tipoArrendodamentoUnidadeMedida = tipoArrendodamentoUnidadeMedida;
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.UnidadeMedidaDeId = this.UnidadeMedidaDeId?.value;
    return retorno;
  }
}
